import { cocoonInitializer } from '../utils/cocoon'
import fetchOptions from '../utils/fetch-options'

cocoonInitializer($, jQuery)

document.addEventListener 'turbolinks:load', ->
  forms = ['the-completes-data-form', 'the-account-form']

  for form in forms
    el = document.getElementById form
    if el?
      $(el).on 'cocoon:before-insert', (eve, item) ->
        master = item[0].querySelectorAll('select')[0]
        theGodfather = $(master).parent().parent().parent()[0]
        theGodfather.dataset['masterDetailVictim'] = "##{item[0].querySelectorAll('select')[1].id}"
